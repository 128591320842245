import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
import _ from 'lodash'

const isDev = (process.env.NODE_ENV === "development" && _.isUndefined(process.env.REACT_APP_USE_FIREBASE))

if (!firebase.apps.length) {

    if (!isDev) {

        /* TODO: Need better way to handle this */

        firebase.initializeApp(require('../../../src/firebaseConfig.json'))
    }
}

export const db = isDev ? {} : firebase.firestore()
export const storage = isDev ? {} : firebase.storage()
export const storageConstants = isDev ? {} : firebase.storage
export const analytics = isDev ? {} : firebase.analytics()

const parseQueryString = (location) => {

    return location.search
        .substr(1)
        .split('&')
        // eslint-disable-next-line func-names
        .reduce(function (q, query) {
            const chunks = query.split('=');
            const key = chunks[0];
            const value = chunks[1];
            return (q[key] = value, q);
        }, {});
}

const simulateLogin = () => {

    return (process.env.NODE_ENV === "development") ?
        firebase.auth()
            .signInWithEmailAndPassword(process.env.REACT_APP_FIREBASE_USER, process.env.REACT_APP_FIREBASE_PASS)
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error(error)
            })
        : Promise.resolve()
}

export const upload = (file, updateProgress, updateSize, metaData) => {

    return new Promise((resolve, reject) => {

        getUser()
            .then(user => {

                if (isDev) {

                    if (!_.isUndefined(updateSize)) { updateSize(1000) }

                    setTimeout(function () {
                        if (!_.isUndefined(updateProgress)) { updateProgress(20) }
                    }, 1000)

                    setTimeout(function () {
                        if (!_.isUndefined(updateProgress)) { updateProgress(60) }
                    }, 2000)

                    setTimeout(function () {
                        if (!_.isUndefined(updateProgress)) { updateProgress(100) }
                        resolve(
                            {
                                storageType: 'gcp',
                                bucket: 'foo',
                                path: `users/someuploadedfilehere.pdf`
                            }
                        )
                    }, 3000)

                } else {

                    const storageRef = storage.ref("users")

                    const uploadTask = storageRef.child(`/${user.uid}/${file.name}`)
                        .put(file, metaData || { 'contentType': file.type })

                    uploadTask.on(storageConstants.TaskEvent.STATE_CHANGED,
                        (snapshot) => {

                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                            if (!_.isUndefined(updateProgress)) { updateProgress(progress) }
                            if (!_.isUndefined(updateSize)) { updateSize(snapshot.totalBytes) }
                        },
                        (error) => reject(error),
                        () =>

                            resolve(
                                {
                                    storageType: 'gcp',
                                    bucket: uploadTask.snapshot.metadata.bucket,
                                    path: `${uploadTask.snapshot.metadata.fullPath}`
                                }
                            )
                    )
                }
            })
            .catch(error => resolve(error))
    })
}

export const signout = () => {

    /*
     NOTE: We can't can only try a signout and redirect user back to login. If the
     signout attempt here fails, then we're trying again in login
    */

    if (!isDev) {

        firebase.auth().signOut()
            .catch(error => {
                /* Can't do much here ... or can we? */
            });
    }

    (isDev) ?
        // eslint-disable-next-line no-console
        console.log('User has attemped a log out ... sending back to login')
        : window.location.replace('/?signout=yes');
}

export const setMessageSeen = (messageType) => {

    if (isDev) {

        return Promise.resolve()

    } else {

        return getUser()
            .then(user => {

                return db.collection("users").doc(user.uid).get()
                    .then(doc => {

                        if (doc.exists) {

                            return db.collection("users").doc(user.uid).collection("messaging")
                                .doc(messageType).set({
                                    showMessage: false,
                                    acceptedOn: firebase.firestore.Timestamp.fromDate(new Date())
                                })

                        } else {

                            throw Error('Trying to set message as showMessage on invalid user')
                        }
                    })
            })
    }
}

export const getMessageType = (user) => _.has(user, 'roles.rating') ? 'rating' : 'portal'

const getShowMessage = (uid, type) => {

    if (isDev) {

        return {
            [type]: false,
            acceptedTC: false
        }

    } else {

        return db.collection("users").doc(uid).collection("messaging").get()
            .then(snap => {

                return _.fromPairs(_.map(snap.docs, obj => {

                    return [[obj.id], obj.data().showMessage]
                }))
            })
    }
}

export const storeWebError = (app, data) => {

    /* 
        Since this is trying to store an error, all it can do is try its best. So clients
        dont really have to chain in since this wont return an error anyway
    */
    
    return new Promise(resolve => {

        if (isDev) {

            resolve()

        } else {
    
            return getUser()
                .then(user => {
    
                    return db.collection("webErrors").doc(`${user.uid}-${new Date().getTime()}`).set({
                            email: user.email,
                            app,
                            data
                        })
                        .then(() => {
                            console.log("Web error saved")
                            resolve()
                        })
                        .catch(error => {
                            console.log('Error saving web error:', error)
                            resolve()
                        })
                })
                .catch(error => {
                    console.log('Error saving web error:', error)
                    resolve()
                })
        }
    })
}

export const getUser = (ghoster) => {

    if (isDev) {

        console.log('Returning dev user')

        return Promise.resolve(
            {
                uid: '12345',
                email: 'mmantle@gmail.com',
                firstName: 'Mickey',
                lastName: 'Mantle',
                roles: ['employee', 'fax'],
                pendingRoles: [],
                showPatchMessage: true,
                showTCs: true,
                crmId: 'crm', /* should not be needed for employees */
                agentId: 'internal' /* Not sure if this is going to ever work for employees since they wont have agent id */
            }
        )

    } else {

        return db.collection("users").doc(firebase.auth().currentUser.uid)
            .get()
            .then(userDoc => {

                const user = userDoc.data()

                /*
                 NOTE: User should not get here with nothing in either pendingRoles or roles,
                       so for now this will make that assumption
                */

                const pendingRoles = user.pendingRoles || {}
                const roles = user.roles || {}

                /*
                 Registration requies first, last for all and "technically" this should
                 be the same in all - or ideally on in one role (those should be known)
                 and all other roles are additive. Or maybe shifting demographic info to
                 root?
                */

                const firstAndLastFromRoles = _.head(_.filter(roles, role => _.has(role, 'firstName'))) || {}
                const firstAndLastFromPending = _.head(_.filter(pendingRoles, role => _.has(role, 'firstName'))) || {}
                const userInfo = firstAndLastFromRoles || firstAndLastFromPending

                const throwInvalidUser = () => { throw new Error('Invalid User') }
  
                const needsAgentId = _.has(roles, 'broker') /* Not sure if something else will come into play other other than just being broker */
                const needsCrmId = needsAgentId && _.has(roles, 'rating')
                
                const brokerData = _.has(roles, 'broker') ? {
                    agentId: _.has(user, 'agentId') ? user.agentId : undefined,
                    crmId: _.has(user, 'crmId') ? user.crmId : undefined
                } : {}

                /* Cant have the broker trying to do anything if agent id is not set */
                
                if (needsAgentId && _.isUndefined(brokerData.agentId)) { throwInvalidUser() }

                /* Same for CRM ID */
                
                if (needsCrmId && _.isUndefined(brokerData.crmId)) { throwInvalidUser() }

                const { firstName, lastName } = _.isUndefined(userInfo) ? throwInvalidUser() :
                    { firstName: userInfo.firstName, lastName: userInfo.lastName }

                return {
                    ...{
                        uid: firebase.auth().currentUser.uid,
                        email: user.email,
                        firstName: firstName,
                        lastName: lastName,
                        roles: _.keys(roles),
                        pendingRoles: _.keys(pendingRoles),
                        ghoster
                    }, ...brokerData
                }
            })
            .then(user => {

                const messageType = getMessageType(user)

                return getShowMessage(user.uid, messageType)
                    .then(result => {

                        const toMerge = {
                            showPatchMessage: _.has(result, messageType) ? result[messageType] : false,
                            showTCs: _.has(result, 'showTCs') ? result['showTCs'] : true
                        }

                        return {...user, ...toMerge}
                    })
            })
            .catch(error => {

                console.error(error)
            })
    }
}

export const getToken = () => {

    return firebase.auth().currentUser.getIdTokenResult(true)
        .then(idTokenResult => {
            return idTokenResult.token
        })
}

export const initializeUser = (onLoggedIn) => {

    if (isDev) {

        getUser().then(dbUser => {

            onLoggedIn(dbUser)

            return () => { console.log('Unsubscribed ...') }
        })

    } else {

        return simulateLogin().then(() => {

            const checkForGhost = () => {

                return new Promise((resolve, reject) => {

                    const params = parseQueryString(window.location)
                    const token = params.t
                    const ghoster = params.ghoster

                    const uri = window.location.toString()

                    if (uri.indexOf('?') > 0) {

                        window.history.replaceState(
                            {},
                            document.title,
                            uri.substring(0, uri.indexOf('?')),
                        )
                    }
        
                    if (!_.isUndefined(token) && !_.isUndefined(ghoster)) {
            
                        return firebase.auth().signInWithCustomToken(token)
                            .then(userCreds => {
                                
                                console.log(`Ghosting ... [${userCreds.user.uid}] by [${ghoster}]`)

                                resolve(ghoster)
                            })
                            .catch(error => {
                                
                                firebase.auth().signOut()
                                reject(error)
                            })
                        
                    } else {
                        
                        resolve()
                    }
                });
            }

            return checkForGhost()
                .then((ghoster) => {

                    return firebase.auth().onAuthStateChanged(user => {

                        if (user) {

                            console.log("onAuthStateChanged complete and user should be good")
                            console.log(user)
        
                            if (onLoggedIn) getUser(ghoster).then(dbUser => onLoggedIn(dbUser))
        
                        } else {
        
                            (process.env.NODE_ENV === "development") ?
                                // eslint-disable-next-line no-console
                                console.log('User has been logged out or disabled ... sending back to login')
                                : window.location.replace('/toLogin');
                        }
                    })
                })
        })
    }
}

export const restConfig = () => {

    const baseHeaders = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const addHeader = (toAdd) => { return { ...baseHeaders, headers: { ...baseHeaders.headers, ...toAdd } } }

    return (isDev ? Promise.resolve("12345") : getToken())
            .then(token => getUser().then(dbUser => { return { token, user: dbUser.email }}))
            .then(tokenAndUser => addHeader({ Authorization: `Bearer ${tokenAndUser.token}`, user: tokenAndUser.user }))
}
