import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import date from 'date-and-time'
import { init, walk } from './Walker'
import Constants from './Constants'

const WalkTypes = {
    Stop: 0,
    Clean: 1,
    ConfigChange: 2,
    ValueChange: 3
}

export const RatingEngineContext = React.createContext({})

export const RatingEngineProvider = (props) => {

    // console.log('Rendering RatingEngineProvider ....')

    /*
     Flag that inidicates caller want renders for each of the field level updates during a walk
    */

    // const { fieldLevelUpdates } = props

    /*
     Current config as set by setConfig function along with what to do - just to keep from 
     having multiple useEffects
    */

    const [config, setConfig] = useState({
        uiDelegate: undefined,
        carrier: undefined,
        plan: undefined,
        census: undefined,
        defaults: undefined,
        walkType: WalkTypes.Stop,
        valueChange: undefined,
        isMulti: false
    })

    /* 
     Flag to tell UI that work is going on
    */

    const [walking, setWalking] = useState(false)

    /* 
     Fields used to render form
    */

    const [fields, setFields] = useState(undefined)

    /*
     Rate data
    */

    const [rateData, setRateData] = useState({ ratesAvailable: false })


    /*
     Latest data from call to rating engine
    */

    const [engineData, setEngineData] = useState(undefined)

    /*
     Way to store off original defaults - since they can get updated - not sure if this is long-term since it could cause re-renders
    */
    
    const [originalDefaults, setOriginalDefaults] = useState(undefined)

    const startWalk = async (uiDelegate, carrier, plan, census, defaults, optionsMap, enteredInPast, isMulti, fieldListCallback) => {

        const initContent = await init(uiDelegate, carrier, plan, census, isMulti)

        if (initContent.continue) {

            const fieldInfo = initContent.fieldInfo

            if (!_.isUndefined(fieldListCallback)) fieldListCallback(initContent.fieldInfo)

            const mergedRemaining = (enteredInPast.length === 0) ? initContent.required : _.map(initContent.required, pair => {

                return _.find(enteredInPast, pastPair => pastPair[0] === pair[0]) || pair
            })

            const result = await walk(uiDelegate, carrier, plan, census, fieldInfo, mergedRemaining, optionsMap,
                [], [], defaults, isMulti)
            
            return result

        } else {

            return initContent
        }
    }

    /*
     The design of all of this is very simple. Single useEffect that works off walkType. This approach is basically the 
     same as use useReducer where the action is the walkType ... but ... there's still some debate into the best pattern
     for mixing state change and async. At least at the time this note was created, the recommended approach is to use a 
     middleware dispatch to perform the async work and then forward to state managing dispatch. That can be tried here
     easily since those 2 sections are clearly separated below. 
    */
    useEffect(() => {

        // console.log('Calling useEffect ....')

        const onInvalid = (type, name) => () => {

            /* 
             This give the UI a chance to tell rating engine a value is invalid and make sure that anything
             below is disabled. Technically, this just applies to free text fields
            */
            
            let dummyType = undefined

            switch (type) {

                case Constants.APIKeys.Long:
                case Constants.APIKeys.Percent:

                    dummyType = Constants.Non400DelegateTriggerValue
                    break

                default:

                    throw new Error(`Calling onInvalid for types other than Long and Percent is not supported`)
            }

            // console.log(`Calling onInvalid [${name}] - [${dummyType}]`)

            setConfig({ ...config, valueChange: { key: name, value: dummyType }, walkType: WalkTypes.ValueChange })
        }

        const onValueChange = (type, name) => (value) => {

            let mappedValue = undefined

            switch (type) {
                
                case Constants.APIKeys.Percent:

                    /* 
                        planRtmPercent swizzle >>> PART 4: When the value is selected, we need to add the 0.01
                        so it matches what the rating engine is expecting
                    */
                       
                   switch (name) {

                       case 'planRtmPercent':

                           mappedValue = (Number(value) + Constants.MajorMedRateLoadFactor)
                           break

                        default:

                            mappedValue = Number(value)
                    }

                    break
                
                case Constants.APIKeys.Long:
                case Constants.APIKeys.Double:

                    mappedValue = Number(value)
                    break
                
                case Constants.APIKeys.Boolean:
                    
                    mappedValue = value === 1
                    break

                default:

                    mappedValue = value
            }

            // console.log(`Value changed: [${name}][${mappedValue}]`)

            setConfig({ ...config, valueChange: { key: name, value: mappedValue }, walkType: WalkTypes.ValueChange })
        }

        const setFieldsAndRateData = (result, safeToReturnRates) => {

            const getTypeDescByName = (name) => {

                const found = _.find(result.typesMap, obj => obj.name === name)

                if (_.isUndefined(found)) {

                    throw new Error(`Unable to find description and type for ${name}`)

                } else {

                    return {
                        desc: found.desc,
                        help: found.help,
                        type: found.type
                    }
                }
            }

            const setOptions = (type, name) => {

                const inOptions = result.optionsMap[name]

                const genDates = () => _.map(_.range(12), index => {

                    const formatPattern = 'MM/01/YYYY'
                    
                    return date.format(date.addMonths(date.parse(date.format(new Date(), formatPattern),
                        formatPattern), index), formatPattern)
                })

                /* 
                 NOTE: At this time, dates are either free form (requiring special date control) or options. Seeing that
                       the only Date at this time is effective date, it seems logicial to to just normalize on list
                       of first of months
                */
                  
               switch (type) {

                   case Constants.APIKeys.Date:

                       const dateArray = genDates()

                       return {
                           type: Constants.OptionTypes.Array,
                           options: dateArray,
                           length: dateArray.length
                       }
                   
                   case Constants.APIKeys.Boolean: 
                       
                       /* 
                        Booleans should already have options but they may not map to something presentable,
                        so just handle that here
                       */
                       
                       const createIndexedArray = (options) => {

                           return _.map(options, boolVal => {

                               return {
                                   index: boolVal ? 1 : 0,
                                   desc: boolVal ? 'Yes' : 'No'
                               }
                           })
                       }
                       
                       const mappedOptions = createIndexedArray(_.isUndefined(inOptions) ? [true, false] : inOptions.options)

                       return {
                           type: Constants.OptionTypes.IndexedArray,
                           options: mappedOptions,
                           length: mappedOptions.length
                       }

                   case Constants.APIKeys.Percent:

                       /* 
                        planRtmPercent swizzle >>> PART 1: This is "hopefully" the only time we see this, but for the planRtmPercent field, we need to 
                        minus out the 0.01 so the users dont see that. Odd and nasty I know, but not much we can 
                        do without hidding the 0.01 in the rating engine which will mess up auditing
                       */
                       
                       switch (name) {

                           case 'planRtmPercent':

                               if (!_.isUndefined(inOptions) && _.has(inOptions, 'options')) {

                                   return {
                                       ...inOptions,
                                       options: _.map(inOptions.options, v => Number(v) - Constants.MajorMedRateLoadFactor)
                                   }
                                   
                               } else {

                                   return inOptions;
                               }

                           default:
                               return inOptions;
                       }
                   
                   case Constants.APIKeys.String:
                       
                       /* 
                        All Strings have to be options but theres a case where an error could be returned first 
                        and all we have is a validation error
                       */
                       
                       if (!_.isUndefined(result.validation) && _.isUndefined(inOptions)) {

                            return {
                                type: Constants.OptionTypes.Array,
                                options: [],
                                length: 0
                            }                         
                           
                       } else {

                           return inOptions
                       }
                   
                   default:

                       return inOptions
               }
            }

            const createFields = (arrayOfPairs, isEnabled) => _.map(arrayOfPairs, pair => {

                const { type, help, desc } = getTypeDescByName(pair[0])

                const getValue = () => {

                    if (_.isUndefined(pair[1])) {

                        /* There might be a default that might work */
                        
                        return config.defaults[pair[0]]

                    } else {

                       /* 
                        planRtmPercent swizzle >>> PART 2: The swizzled value will not be whats being stored, so minus out the 
                        0.01 so it lines up with the options
                       */

                        switch (pair[0]) {
                            case 'planRtmPercent':
                                return pair[1] - Constants.MajorMedRateLoadFactor
                            default:
                                return pair[1]
                        }
                    }
                }

                return {
                    name: pair[0],
                    value: (type === Constants.APIKeys.Boolean) ? getValue() ? 1 : 0 : getValue(),
                    desc,
                    help, 
                    type,
                    options: setOptions(type, pair[0]),
                    isEnabled,
                    isLast: _.last(_.concat(result.input, result.remaining))[0] === pair[0],
                    onInvalid: onInvalid(type, pair[0]),
                    onValueChange: isEnabled ? onValueChange(type, pair[0]) : () => { }
                }
            })

            const enabledFields = createFields(result.input, true)
            const disabledFields = result.remaining.length === 0 ? [] : createFields(_.tail(result.remaining), false)

            const createCurrent = () => {

                const { type, help, desc } = getTypeDescByName(result.next)

                return [{
                    name: result.next,
                    /* 
                        planRtmPercent swizzle >>> PART 3: When current, we need to make sure to minus out the 0.01
                    */
                    value: result.next === 'planRtmPercent' ? Number(result.lastAttempt) - Constants.MajorMedRateLoadFactor : result.lastAttempt,
                    desc,
                    help,
                    type,
                    options: setOptions(type, result.next),
                    isCurrent: true,
                    isEnabled: true,
                    isLast: result.remaining.length === 1 && _.head(result.remaining)[0] === result.next,
                    onInvalid: onInvalid(type, result.next),
                    onValueChange: onValueChange(type, result.next),
                    isError: !_.isUndefined(result.validation),
                    errorMessage: result.validation
                }]
            }
 

            setFields(_.concat(enabledFields, result.isError ? createCurrent() : [], disabledFields))
            setRateData((result.isError || !safeToReturnRates) ? { ratesAvailable: false } : { ...result.rateData, ratesAvailable: true })
        }

        if (config.walkType !== WalkTypes.Stop) {

            console.log('Starting to walk ....')

            const walkType = config.walkType

            /* Make sure that this is all we're doing right now */

            setConfig({ ...config, walkType: WalkTypes.Stop })

            /* Tell the UI that we're working */

            setWalking(true)

            const work = () => {

                switch (walkType) {

                    case WalkTypes.Clean:

                        return startWalk(config.uiDelegate, config.carrier, config.plan, config.census, config.defaults, {}, [], config.isMulti)

                    case WalkTypes.ConfigChange:

                        return startWalk(config.uiDelegate, config.carrier, config.plan, config.census, config.defaults,
                            engineData.optionsMap, engineData.pastInput, config.isMulti)

                    case WalkTypes.ValueChange:

                        return walk(config.uiDelegate, config.carrier, config.plan, config.census, engineData.typesMap, engineData.remaining,
                            engineData.optionsMap, engineData.pastInput, engineData.input, config.defaults, config.isMulti, config.valueChange.key, config.valueChange.value)

                    default:

                        /*
                         If you add a new walk type ... PLEASE ... specify a specific handler to keep things clear
                        */

                        throw new Error(`Unsupported walk type [${walkType}]`)
                }
            }

            work()
                .then(result => {

                    console.log(result)

                    if (result.continue) {

                        /* 
                         These 2 calls can yield 3 renders ... might be better to put all into single object to reduce number of updates
                         but ... for now ... we'll keep them separate to allow different components to easily monitor what they want (at least thats the idea)
                        */

                        setEngineData(result)
                        setFieldsAndRateData(result, true)


                    } else {

                        /* 
                         NOTE: This means that the UI delegate was called and the UI should have put itself into a different state.
                               Not sure what can be done here since no matter what the setWalking is set to false
                        */

                        /* The following is a complete reset which means recovery is difficult - iow, the user has to restart from scratch */
                        
                        /*
                        setConfig(_.fromPairs(_.map(config, (value, key) => {

                            if (key === 'walkType') {
                                return ['walkType', WalkTypes.Stop]
                            } else {
                                return [key, undefined]
                            }
                        })))
                        */
                        
                        /* Always make sure the engineData is defined before calling setFieldsAndRateData */
                        
                        if (!_.isUndefined(engineData)) {

                            /* This should allow the UI to get the last submitted value to the engine even if it didn't get accepted */
                            
                            const withLastAttempt = (!_.isUndefined(config.valueChange) && (engineData.next === config.valueChange.key)) ?
                                { ...engineData, lastAttempt: config.valueChange.value } : engineData
                        
                            /* Rates will never go back if we couldn't make it through a step cleanly */
                            
                            setFieldsAndRateData(withLastAttempt, false)
                        }
                    }

                    setWalking(false)
                })
        }

    }, [config, engineData])

    /*
     uiDelegate: (no walk actions) Callback handler to UI for specific rating engine events. The callbacks can return a Promise to 
                  be executed in-line or nothing at all.
     carrier: (new walk using current input data): Change of carrier string from provider mappings endpoint
     plan: (new walk using current input data): Plan returned from provider mappings endpoint
     census: (replay last step with new census): Correct rating engine census object
     defaults: (new walk using new defaults clearing out old user data): Object containing valid engine fields for matching carrier.
                Default values will be simply used as user input and active a step - which could yield an error if the default 
                value is not in the most recent options or simply rejected by the engine
     isMulti: Indicates whether or not to use /multiRates endpoint instead of /rates
    */
    const setConfiguration = (uiDelegate, carrier, plan, census, defaults, isMulti) => {

        console.log("In setConfiguration ...")

        if (_.compact([uiDelegate, carrier, plan, census]).length !== 4) {

            throw new Error("Invalid parameters passed to setConfiguration function - all params are required")

        } else if (_.isUndefined(engineData)) {

            console.log("Starting walk from scratch")

            const safeDefaults = defaults || {}

            const initConfig = {
                uiDelegate,
                carrier,
                plan,
                census,
                defaults: safeDefaults,
                walkType: WalkTypes.Clean,
                isMulti: isMulti || false
            }

            setOriginalDefaults(_.clone(safeDefaults))
            setConfig(initConfig)

        } else if (!_.isUndefined(engineData.pastInput)) {

            console.log(`On config change`)

            const safeDefaults = defaults || {}

            setOriginalDefaults(_.clone(safeDefaults))

            /* 
             Since this is a change, we don't want to loose what the user has been doing. So, we
             merge the input into the defaults (which may have changed as well). This means that the current
             input will take precedence - since these are values the user has selected. Next, the startWalk
             will take the pastInput (which is data not used but something the user entered at some point) and
             merge into the new remaining list. Since the remaining gets pruned as walking, the values merged
             from past will naturally find a way either into the input or back into past entry storage
            */
            
            const sessionHistory = _.merge(_.clone(config.defaults), _.clone(safeDefaults))
            
            const mergedDefaults = _.merge(_.merge(_.clone(sessionHistory), _.fromPairs(engineData.pastInput)), _.fromPairs(engineData.input))

            setConfig({ ...config, ...{ defaults: mergedDefaults, carrier, plan, census, walkType: WalkTypes.ConfigChange, isMulti: isMulti || false } })
        }
    }

    const reset = (resetDefaults) => {
        
        if (_.compact([config.uiDelegate, config.carrier, config.plan, config.census]).length === 4) {

            console.log('Clearing now')

            const newDefaults = resetDefaults || originalDefaults

            setConfig({ ...config, defaults: newDefaults, walkType: WalkTypes.Clean })
        }
    }

    /* 
     The values passed out are what we expect clients to react to. All cause a re-render here that we'd want
     to ripple down

     Passing typesMap out clients can get at all available fields and descripitons
    */
    
    const typesArray = (_.isUndefined(engineData) || _.isUndefined(engineData.typesMap)) ? [] : engineData.typesMap

    return (
        <RatingEngineContext.Provider value={{ walking, setConfiguration, reset, fields, rateData, typesArray }}>
            {props.children}
        </RatingEngineContext.Provider>
    );
}