import React, { useState, useEffect } from 'react'
import { initializeUser, restConfig, signout, upload, db, storage, storageConstants, analytics, setMessageSeen, getMessageType, storeWebError } from './backend'
import _ from 'lodash'

const refreshRestConfig = (setFn) => {

    setTimeout(() => {
        restConfig().then(config => setFn(config))
      }, (20*60*1000));
}

/* To level-roles so far - any of these in user's roles would indicate they've completed registration */

const topLevelRoles = ['hr-rep', 'broker', 'employee', 'member']

const cleanExpectedTopLevelRoles = (toClean) => {

    return _.filter(toClean, toCheck =>
        _.find(topLevelRoles, topLevelRole => toCheck === topLevelRole))
}

const userHasTopLevelRole = (allowedTopLevelRoles, user) => {

    /* User can't be multiple top-level roles so make sure they only have 1 */
    
    console.log(`Allowed Top-Level Roles: ${_.join(allowedTopLevelRoles, ",")}`)
    console.log(`User's Roles: ${_.join(user.roles, ",")}`)

    return _.filter(user.roles, usersRole =>
        _.find(allowedTopLevelRoles, topLevelRole => usersRole === topLevelRole)).length == 1
}

const isAllowed = (acceptedPendings, acceptedRoles, user) => {

    const matchWith = (toTest, expected, onFunc) =>
        _.filter(toTest, role => _.find(expected, target => role === target))
    
    const common = _.intersection(acceptedPendings, acceptedRoles)
    const onlyPendings = _.difference(acceptedPendings, common)
    const onlyRoles = _.difference(acceptedRoles, common)
    const allUserRoles = _.union(user.pendingRoles, user.roles)

    const pendingCheckLen = matchWith(user.pendingRoles, onlyPendings).length
    const roleCheckLen = matchWith(user.roles, onlyRoles).length    
    const commonCheckLen = matchWith(allUserRoles, common).length 

    return commonCheckLen === common.length
        && pendingCheckLen === onlyPendings.length
        && roleCheckLen === onlyRoles.length
}

export const FirebaseDB = db

export const FirebaseStorage = storage

export const FirebaseStorageConstants = storageConstants

export const FirebaseAnalytics = analytics

export const FirebaseContext = React.createContext({})

export const FirebaseProvider = ({ testUser, pendingRoles, roles, allowedTopLevelRoles, children }) => {

    const [userData, setUserData] = useState({
        user: undefined,
        restConfig: undefined,
        isLoading: true
    })

    const setPatchMessageSeen = () => {

        return setMessageSeen(getMessageType(userData.user))
            .then(() => {

                setUserData({
                    user: {...userData.user, showPatchMessage: false},
                    restConfig: userData.restConfig,
                    isLoading: false
                })
            })
    }

    const setTCsAccepted = () => {

        return setMessageSeen('showTCs')
            .then(() => {

                setUserData({
                    user: {...userData.user, showTCs: false},
                    restConfig: userData.restConfig,
                    isLoading: false
                })
            })
    }

    useEffect(() => {

        const startFn = async () => {

            const unsubscribe = await initializeUser(dbUser => {

                const cleanedAllowedTopLevelRoles = cleanExpectedTopLevelRoles(allowedTopLevelRoles || [])

                const roleCheckResult = cleanedAllowedTopLevelRoles.length > 0
                    ? userHasTopLevelRole(cleanedAllowedTopLevelRoles, dbUser) : isAllowed(pendingRoles || [], roles || [], dbUser)

                if (roleCheckResult) {

                    restConfig()
                        .then(config => {

                            setUserData({
                                user: testUser || dbUser,
                                restConfig: config,
                                isLoading: false
                            })
                        })
                        .catch(error => {
                            
                            console.error(error)
                        })

                } else {

                    signout()
                }
            })

            return unsubscribe
        }

        const unsubscribe = startFn()

        return () => {

            unsubscribe.then(u => {
                u()
            })
        }
    }, [])

    useEffect(() => {
        
        if (!_.isUndefined(userData.restConfig)) {  

            console.log('Running refreshRestConfig ...')
            
            refreshRestConfig(config => setUserData(u => { return { ...u, restConfig: config } }))
        }

    }, [userData.restConfig])

    return (
        <FirebaseContext.Provider value={{ storeWebError, upload, signout, setPatchMessageSeen, setTCsAccepted, isUserLoading: userData.isLoading, user: userData.user, restConfig: userData.restConfig }}>
            {children}
        </FirebaseContext.Provider>
    );
}