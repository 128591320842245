import _ from 'lodash'
import { makeCall, InternalCommErrorCodes } from './Comm'
import Constants from './Constants'

function ResponseInterpreter(delegate) {

    this.delegate = delegate
}

ResponseInterpreter.prototype.getHeaders = function () {
    return this.delegate.getHeaders()
}

ResponseInterpreter.prototype.onNoRecoveryError = function () {
    return this.delegate.onNoRecoveryError()
}

ResponseInterpreter.prototype.onTimeout = function () {
    return this.delegate.onTimeout()
}

ResponseInterpreter.prototype.onUnexpectedErrorResponse = function (status, data) {
    return this.delegate.onUnexpectedErrorResponse(status, data)
}

ResponseInterpreter.prototype.onBusy = function () {
    return this.delegate.onBusy()
}

ResponseInterpreter.prototype.onNoMatchingProvider = function () {
    return this.delegate.onNoMatchingProvider()
}

ResponseInterpreter.prototype.onSheetValidationError = function (msg) {
    return this.delegate.onSheetValidationError(msg)
}

ResponseInterpreter.prototype.onUnrecoverableServerFailure = function () {
    return this.delegate.onUnrecoverableServerFailure()
}

ResponseInterpreter.prototype.onBadData = function (data) {
    throw new Error('Needs to be implemented')
}

ResponseInterpreter.prototype.onGotRates = function (rates) {
    throw new Error('Needs to be implemented')
}

function BadDataOnlyResponseInterpreter(delegate) {

    ResponseInterpreter.call(this, delegate)
}

BadDataOnlyResponseInterpreter.prototype = Object.create(ResponseInterpreter.prototype)

BadDataOnlyResponseInterpreter.prototype.onGotRates = function (rates) {

    /* This would be terrible at this time and mean something with the rating engine is all off, so just blow up */

    throw new Error('Got rates when expecting 400 response with types')
}

function CallForAllPossibleResponseInterpreter(delegate) {

    BadDataOnlyResponseInterpreter.call(this, delegate)
}

CallForAllPossibleResponseInterpreter.prototype = Object.create(BadDataOnlyResponseInterpreter.prototype)

CallForAllPossibleResponseInterpreter.prototype.onBadData = function (data) {

    const allPossible = data[Constants.APIKeys.RequiredKey]

    return {
        continue: true,
        required: _.map(allPossible, obj => [obj.name, undefined]),
        fieldInfo: allPossible
    }
}

function SingleStepResponseInterpreter(delegate, input, options, target, next, typesMap) {

    this.input = input
    this.options = options
    this.target = target
    this.next = next
    this.typesMap = typesMap

    ResponseInterpreter.call(this, delegate)
}

SingleStepResponseInterpreter.prototype = Object.create(ResponseInterpreter.prototype)

SingleStepResponseInterpreter.prototype.onBadData = function (data) {

    /* 1. Pull all the maps ... just to make things a bit easier */

    const validationMap = data[Constants.APIKeys.ValidationKey] || {}
    const invalidFormatMap = data[Constants.APIKeys.InvalidFormatKey] || {}
    const invalidSelectionMap = data[Constants.APIKeys.InvalidSelectionKey] || {}
    const invalidTypeMap = data[Constants.APIKeys.InvalidTypeKey] || {}

    const setOptions = (type, optionsToSet) => {

        if (_.isObject(_.head(optionsToSet))) {

            return {
                type: Constants.OptionTypes.IndexedArray,
                options: optionsToSet,
                length: optionsToSet.length
            }

        } else {

            const asArray = _.map(_.split(optionsToSet, ","), v => {

                switch (type) {

                    case Constants.APIKeys.Boolean:

                        return _.toLower(v) === 'true'

                    case Constants.APIKeys.Long:
                    case Constants.APIKeys.Percent:
                    case Constants.APIKeys.Double:

                        return Number(v)

                    default:

                        return v
                }
            })

            return {
                type: Constants.OptionTypes.Array,
                options: asArray,
                length: asArray.length
            }
        }
    }

    const pullValue = (key, valueType) => {

        /* This just acts as a helper to correctly format to the end goal to keep from having to recheck */

        const format = (type, value, formatter) => {

            return _.isUndefined(value) ? undefined : {
                [type]: _.isUndefined(formatter) ? value : formatter(valueType, value)
            }
        }

        /* Just pulling head because the rating engine would not return same key in more than 1 map */

        return _.head(_.compact([
            format(Constants.SingleStepValidationTypes.Validation, validationMap[key]),
            format(Constants.SingleStepValidationTypes.Format, invalidFormatMap[key]),
            format(Constants.SingleStepValidationTypes.Options, invalidSelectionMap[key], setOptions),
            format(Constants.SingleStepValidationTypes.Type, invalidTypeMap[key])]))
    }

    /* 
     We sent off a request to the rating engine and we're targeting a specific field (target constructor value).
     We also included (if needed) the next field. Here's what could happen:

        1. Target was accepted (in none of the lists) and got response for next list (MOST COMMON)
        2. Target was accepted (in none of the lists) and got response for next with no list (MEANS NEXT IS FREE_ENTRY)
        3. Target was not accepted (either in invalid-selection, invalid-format, or validation)

        ** invalid type should not happen here - that would mean something else is off
    */

    const pullType = (key) => {

        return _.isUndefined(key) ? "String" : _.find(this.typesMap, obj => obj.name === key).type || "String"
    }

    const resultForNext = pullValue(this.next, pullType(this.next))
    const resultForTarget = pullValue(this.target, pullType(this.target))

    /* Check for a cell validation that is not related to either target or current */

    if (!_.isEmpty(_.omit(validationMap, [this.next, this.target]))) {

        /* Probably not the best to do long-term, but for now we're just going to support 1 cell validation at a time */

        const perviousError = _.head(_.keys(_.omit(validationMap, [this.next, this.target])))

        return {
            ...(pullValue(perviousError, pullType(perviousError))),
            continue: true,
            errorOn: perviousError,
            status: Constants.SingleStepStatus.PreviousValidationError,
            required: data[Constants.APIKeys.RequiredKey] || {}
        }

    } else if (_.isUndefined(resultForTarget) && !_.isUndefined(resultForNext)) {

        /* Next has an issue but target was accepted */

        return {
            ...resultForNext, status: Constants.SingleStepStatus.TargetAcceptedNextFailed,
            continue: true,
            required: data[Constants.APIKeys.RequiredKey] || {}
        }

    } else if (!_.isUndefined(resultForTarget)) {

        /* Target still has an issue */

        return {
            ...resultForTarget, status: Constants.SingleStepStatus.TargetFailed,
            continue: true,
            required: data[Constants.APIKeys.RequiredKey] || {}
        }

    } else if (_.head(data[Constants.APIKeys.RequiredKey]) !== resultForNext) {

        /* Target was accepted but path has changed on us */

        const newNext = _.head(data[Constants.APIKeys.RequiredKey])

        return {
            ...(pullValue(newNext, pullType(_.isObject(newNext) ? newNext.name : newNext))),
            continue: true,
            status: Constants.SingleStepStatus.TargetAcceptedNextChanged,
            required: data[Constants.APIKeys.RequiredKey] || {}
        }

    } else {

        /* Nothing wrong */

        return {
            continue: true,
            status: Constants.SingleStepStatus.Accepted,
            required: data[Constants.APIKeys.RequiredKey] || {}
        }
    }
}

SingleStepResponseInterpreter.prototype.onGotRates = function (ratesData) {

    const inputWithDescs = _.fromPairs(_.map(this.input, (value, key) => {

        const optionsForKey = this.options[key]

        if (_.isUndefined(optionsForKey)) {

            return [key, value]

        } else {

            return optionsForKey.type === Constants.OptionTypes.IndexedArray ?
                [key, _.find(optionsForKey.options, obj => obj.index === value)] : [key, value]
        }
    }))

    if (!_.has(ratesData, "id")) {

        /* 
         Currently, Gap is the only type that offers multi-plans from single request and this is technically the same 
         as the No Prefs. So in the multi case (at least for now), we're just going to return the same payload as No Prefs
         instead of the normal payload. Multi is only supported for Gap and Gap never has indexed. If other products move
         to multi, then they could stop using indexed.
        */
        
       return {
            continue: true,
            raw: ratesData,
            status: Constants.SingleStepStatus.GotRates
        }

    } else {

        return {
            continue: true,
            id: ratesData[Constants.APIKeys.IdKey],
            requestId: ratesData[Constants.APIKeys.RequestIdKey],
            inputWithDescs,
            raw: ratesData,
            cleaned: _.omit(ratesData, [Constants.APIKeys.WarningsKey, Constants.APIKeys.IdKey, Constants.APIKeys.RequestIdKey]),
            warnings: ratesData[Constants.APIKeys.WarningsKey] || [],
            status: Constants.SingleStepStatus.GotRates
        }
    }
}

const callRatingEngine = (headersPromise, provider, plan, census, input, responseInterpreter, isMulti) => {

    const constructRatingEngineUrl = (provider, plan) => {

        const prefix = (isMulti || false) ? `multiRates` : `rates`

        const host = (process.env.NODE_ENV === "development") ? `http://localhost:3001/rating-engine/v1/${prefix}` : `/rating/${prefix}`

        return `${host}/${provider}?plan=${plan}`
    }

    const wrapResponse = (res, returnIn = false) => {

        const respMsg = { continue: false }

        return new Promise(resolve => {

            if (!_.isUndefined(res) && typeof res.then === "function") {

                return res
                    .then(data => resolve(returnIn ? data : respMsg))
                    .catch(err => {

                        console.error(err)
                        resolve(respMsg)
                    })

            } else {

                resolve(returnIn ? res : respMsg)
            }
        })
    }

    return wrapResponse(headersPromise, true)
        .then(headers => 
            makeCall(
                constructRatingEngineUrl(provider, plan),
                'POST',
                {
                    censusData: census,
                    inputData: input
                },
                headers,
                20000
            )
        )
        .then(response => {

            if (response.isError) {

                switch (response.errorCode) {

                    case InternalCommErrorCodes.callFailedNoStatus:

                        /* 
                         Something client side went wrong. Not a lot that we can do here except report to the user 
                         and hopefully they try again later
                        */

                        return wrapResponse(responseInterpreter.onNoRecoveryError())

                    case InternalCommErrorCodes.callTimedOut:

                        /* 
                         Call timed out ... this is not a good sign since if could mean connectivity is bad/gone
                         or the rating engine didn't reply. Hard to determine which one, so probably best to report
                         to user that something went wrong and try again later - stay generic
                        */

                        return wrapResponse(responseInterpreter.onTimeout())

                    /*
                    -10001: Busy - Server is handling other requests and client should try again in few seconds
                    -10002: No Matching Provider - Provider (carrier) is not supported. This may be due to server not being configured for all carriers.
                    -10003: Sheet Validation Error - When trying to pull rates from sheet, sheet returned an error. This is less likely then getting a 400 with JSON context explaining the errors.
                    -10004: Unknown - Since the rating-engine must log all request and responses, this is a replacement for 500. The message will contain the error text but a client should not display.
                    */

                    case -10001:

                        return wrapResponse(responseInterpreter.onBusy())

                    case -10002:

                        return wrapResponse(responseInterpreter.onNoMatchingProvider())

                    case -10003:

                        return wrapResponse(responseInterpreter.onSheetValidationError(response.errorMessage))

                    case -10004:

                        return wrapResponse(responseInterpreter.onUnrecoverableServerFailure())

                    default:

                        const data = response.response.data
                        const status = response.response.status

                        const testForKeys = (toTest) => {

                            return _.compact([toTest[Constants.APIKeys.ValidationKey], toTest[Constants.APIKeys.InvalidFormatKey],
                                toTest[Constants.APIKeys.InvalidSelectionKey], toTest[Constants.APIKeys.RequiredKey]]).length > 0
                        }

                        if (!_.isUndefined(data) && status === 400 && _.isObject(data) && testForKeys(data)) {

                            return responseInterpreter.onBadData(data)

                        } else {

                            /* 
                             Kind of a hack, but if any of the values in the payload contain Non400DelegateTriggerValue, then
                             this will not be called. It allows the UI to invalidate a previous state (say on that was accepted)
                            */
                            
                            if (_.isUndefined(_.find(_.values(input), value => value === Constants.Non400DelegateTriggerValue))) {

                                return wrapResponse(responseInterpreter.onUnexpectedErrorResponse(status, data))

                            } else {

                                return wrapResponse("")
                            }
                        }
                }

            } else {

                return responseInterpreter.onGotRates(response.response)
            }
        })
}

/* 
    Helper function that will adjust the 2 input arrays so that the view matches the target index.
    The idea is to treat the head and tail as one. The result, using the targetIndex, just moves values
    from the head to the tail. 

    For example, if current looked like:

        const inputs = [1, 2, 3, 4, 5]
        const rest = [6, 7, 8, 9, 10]

    And the user decided to go back to the second field (targetIndex of 1), the result would:

        {
            newHeadArray: [1],
            newTailArray: [2, 3, 4, 5, 6, 7, 8, 9, 10]
        }

    The nice part is that this is just moving the items in the arrays around. So by moving values
    to tail, you could be moving the values the user already entered so you have it when you walk back down.

    Since _.drop and _.take are being used, a targetIndex greater than whats in the headArray is basically 
    an echo and not an error
*/
const resetArraysByIndex = (headArray, tailArray, targetIndex) => {

    /* Take from head array up to target index */
    const newHeadArray = targetIndex >= 0 ? _.take(headArray, targetIndex) : headArray

    /* Get the part of the headArray that will need to be moved to the tailArray */
    const droppedFromHead = targetIndex >= 0 ? _.drop(headArray, targetIndex) : []

    /* Now take the dropped portion and put append to tailArray */
    const newTailArray = _.concat(droppedFromHead, tailArray)

    return {
        newHeadArray,
        newTailArray
    }
}

const sameArray = (array1, array2) => _.isEqual(_.sortBy(array1), _.sortBy(array2))

const step = async (delegate, carrier, plan, census, inputArray, remainingArray,
    enteredInPast, typesMap, optionsMap, key, value, isMulti) => {
    
    /* 
     Step 1: Check for base case - key is undefined - which is officially starting the wak. In that case, the key
             will become the head of the required and value will be dummy value for key type
    */
    const readyKey = _.isUndefined(key) ? _.head(_.head(remainingArray)) : key

    /* 
     Step 2: Determine if the key exists in the current inputArray. If so this means that the user is
             attempting to go back

             -1 will indicate that the user is not trying to go back and instead the key should be the
             head of the remainingArray
    */
    const indexOfNext = _.findIndex(inputArray, i => i[0] === key)

    /* 
     Step 3: Based on what the user is trying to do, reposition the pointer in the inputArray and 
             remainingArray (when seen as 1 complete set) to where the user is attempting to go
    */
    const { newHeadArray, newTailArray } = resetArraysByIndex(inputArray, remainingArray, indexOfNext)

    /*
     Step 4: Now we have the inputs and remaining positioned correctly, we can now ask the backend what to do
             next. The arrays are positioned so the head of the newTailArray is the the 'key' passed into
             this function. Even if we rolled back (so new value for past entry), this is the state were in.
             But, we don't just want to send in this key/value ... we also want to be effecient as possible 
             and "try" to get the inline (if needed - which we know if there anything else in the newTailArray).
    */

    const pairToAddToInput = _.head(newTailArray)

    /* 
     At this point, 'value' is what the user selected/entered. Or ... if there is no value then we wont bother sending
     since we know the server will tell us if the options (if a select) and if this is even the next field
    */
    const inputWithCurrentKey = _.concat(newHeadArray, _.isUndefined(value) ?
        _.isUndefined(pairToAddToInput[1]) ? [] : [[pairToAddToInput[0], pairToAddToInput[1]]] : [[pairToAddToInput[0], value]])

    /* Now look into the remaining and see if theres going to be something else */
    
    const newRemaining = _.tail(newTailArray)
    const nextPair = _.head(newRemaining)
    const nextInLine = _.head(nextPair)

    const result = await callRatingEngine(delegate.getHeaders(), carrier, plan, census, _.fromPairs(inputWithCurrentKey),
        new SingleStepResponseInterpreter(delegate, _.fromPairs(inputWithCurrentKey), optionsMap, readyKey,
            nextInLine, typesMap), isMulti)

    if (result.continue) {

        /* 
            Do a bit of book keeping on the required list. Mainly, we need to be sure that if the required list has changed, we 
            reset the current remaining to match. But, this may mean:
    
            1. Remaining got smaller
            2. Remaining got larger
    
            Seems obvious but we have to be sure to properly track all the different pieces - mainly dealing with what the user
            has already entered. NOTE: You can't just check the status because that won't nessesarily tell if the remaining has changed
        */

        /* Helpers */
        const resultRequiredArray = _.isUndefined(result.required) ? [] : _.has(_.head(result.required), 'name') ?
            _.map(result.required, obj => obj.name) : result.required
        const returnRemainingArray = _.map(newRemaining, obj => obj[0])

        /* The base response is set so the key passed in DID NOT pass validation */

        const baseResponse = {
            isError: true,
            continue: true,
            isValidationError: !_.isUndefined(result[Constants.SingleStepValidationTypes.Validation]),
            next: nextInLine,
            input: newHeadArray,
            remaining: newRemaining,
            pastInput: enteredInPast,
            typesMap, /* NOTE: For now, we're going to 'ASS'-'U'-'ME' that all formats will be returned upfront and would not need to be updated */
            validation: result[Constants.SingleStepValidationTypes.Validation]
        }

        const setOptionsByKey = (keyIn) => {

            return {
                ...optionsMap, [keyIn]: _.isUndefined(result[Constants.SingleStepValidationTypes.Options])
                    ? optionsMap[keyIn] : result[Constants.SingleStepValidationTypes.Options]
            }
        }

        const processResults = (next) => {

            const inputWONext = _.filter(inputWithCurrentKey, pair => pair[0] !== next)
            const removedFromInput = _.find(inputWithCurrentKey, pair => pair[0] === next)
            const backToRemaining = _.isUndefined(removedFromInput) ? [] : [removedFromInput]

            if (sameArray(returnRemainingArray, resultRequiredArray)) {

                /* This is the case where the required is what we expected - no change in direction or required */

                return {
                    ...baseResponse,
                    input: inputWONext,
                    remaining: _.concat(backToRemaining, newRemaining),
                    next,
                    optionsMap: setOptionsByKey(next)
                }

            } else {

                /* 
                    This is the case where the required has changed so we need to adjust things. No matter what, the required from the rating
                    engine is "truth" so in the end thats what the output remaining need to look like. We just need to get the diffs and 
                    move some stuff around so anything the user has done is either stored away or comes back into play.
    
                    NOTE: This should also handle direction changes since next is determined by the head of the remaining in all cases
                */

                const diffsWODirection = _.xor(returnRemainingArray, resultRequiredArray)

                const mergedRemaining = _.map(resultRequiredArray, fieldName => {

                    const fromCurrent = _.find(newTailArray, pair => _.head(pair) === fieldName)
                    const fromPast = _.find(enteredInPast, pair => _.head(pair) === fieldName)

                    return _.isUndefined(fromCurrent) ? (_.isUndefined(fromPast) ? [fieldName, undefined] : fromPast) : fromCurrent
                })

                if (_.isEmpty(_.difference(diffsWODirection, resultRequiredArray))) {

                    /* 
                        Empty means fields were added. So, we need to check the enteredInPast Map and see if there's anything there.
                        We know that the final remaining will be exactly what the server told us so we can just use that
                        to map over and get any past values
                    */

                    return {
                        ...baseResponse,
                        input: inputWONext,
                        remaining: _.concat(backToRemaining, mergedRemaining),
                        pastInput: _.filter(enteredInPast, pair => _.isUndefined(_.find(diffsWODirection, name => name === pair[0]))),
                        next,
                        optionsMap: setOptionsByKey(next)
                    }

                } else {

                    /*
                        Non-empty difference means that fields were removed. The final results will still just be what the 
                        server told us, but we just need to store off any of the users values that they may have previously
                        entered
                    */

                    const removed = _.difference(diffsWODirection, resultRequiredArray)

                    const fromCurrent = _.filter(newRemaining, pair =>
                        !_.isUndefined(_.find(removed, fieldName => pair[0] === fieldName)))

                    return {
                        ...baseResponse,
                        input: inputWONext,
                        remaining: _.concat(backToRemaining, mergedRemaining),
                        pastInput: _.concat(enteredInPast, fromCurrent),
                        next,
                        optionsMap: setOptionsByKey(next)
                    }
                }
            }
        }

        switch (result.status) {

            case Constants.SingleStepStatus.TargetAcceptedNextFailed:

                /*
                    This is a normal step. Server accepted the target (what user entered) and returned next inline. More
                    important is that next inline matches what we think so no need to change direction
                */

                return processResults(_.head(resultRequiredArray))

            case Constants.SingleStepStatus.TargetAcceptedNextChanged:

                /* This is informational at this point since onAccepted handles direction change by looking at the remaining */

                return processResults(_.head(resultRequiredArray))

            case Constants.SingleStepStatus.TargetFailed:

                return processResults(readyKey)

            case Constants.SingleStepStatus.Accepted:

                /* 
                    Remaining dropping next should be the remaining in result - but unlike TargetAcceptedNextFailed there wont be any
                    extra info to provide and the next will be advanced 1. This is most likely a free-form field.
                */

                return processResults(_.head(resultRequiredArray))

            case Constants.SingleStepStatus.PreviousValidationError:

                /* This means that a change caused a cell validation to trigger on past input. For this we need to realign the remaining */

                return processResults(result.errorOn)

            case Constants.SingleStepStatus.GotRates:

                if (!_.isEmpty(resultRequiredArray)) {

                    throw new Error(`Was told rates are available but returned required was not empty ` +
                        `: Expected [] Got [${_.join(resultRequiredArray, ',')}]`)
                }

                /* The following should be enough to properly communicate the state at this time */

                const lastAdded = _.last(_.toPairs(inputWithCurrentKey))[1]

                return {
                    isError: false,
                    continue: true,
                    isValidationError: false,
                    next: undefined,
                    input: _.concat(newHeadArray, [lastAdded]) ,
                    remaining: [],
                    pastInput: _.filter(enteredInPast, pair => pair[0] !== lastAdded[0]),
                    typesMap,
                    optionsMap,
                    validation: undefined,
                    rateData: _.omit(result, "status")
                }

            default:

                throw new Error(`Unhandled status returned from SingleStepResponseInterpreter: [${result.status}]`)
        }

    } else {

        return result
    }
}

const getCensusTotal = (census) => {
 
    if (Array.isArray(census)) {

        return census.length

    } else {

        return _.sum(_.flatMap(census, (value) => _.reduce(_.values(value), (sum, next) => sum + next)))
    }
}

const specialKeyHandler = (key, censusTotal) => {

    switch (key) {

        case "eligibleEmployees":

            return censusTotal

        case "planRtmPercent":

            return 0.05;

        default:

            return undefined
    }
}

/* 
 Sets up everything to start walking over sheet. This would be called once carrier and plan are know.
 Techincally, its best to have the census before making this call. But, the census shouldn't change
 the result "unless" the rater does not allow a census under a certain size - even without considering the 
 state. For this reason, this init is NOT considering cell validations.
*/
export const init = async (delegate, carrier, plan, census, isMulti) => {

    /* Pre-work - technically, this would work without the census - or at least a dummy census */

    const setCensus = (_.isUndefined(census) || getCensusTotal(census) === 0) ? Constants.Test4TierCensus : census

    const initContent = await callRatingEngine(delegate.getHeaders(), carrier, plan, setCensus, {},
        new CallForAllPossibleResponseInterpreter(delegate), isMulti)

    return initContent
}

export const walk = async (delegate, carrier, plan, census, fieldInfo, inRemaining, inOptionsMap, inEnteredInPast, inInput,
    defaults, isMulti, inKey, inValue, loadingCallback, loadingResultCallback) => {
    
    let keepWalking = true
    let result = undefined
    let key = inKey
    let value = inValue
    let input = inInput
    let remaining = inRemaining
    let enteredInPast = inEnteredInPast
    let optionsMap = inOptionsMap || {}

    const onErrorResponse = (msg) => {

        delegate.onUnexpectedErrorResponse(400, msg)

        return { continue: false }
    }

    const censusTotal = getCensusTotal(census)

    // eligibleEmployees

    if (censusTotal === 0) {

        return onErrorResponse("Census total must be greater then zero to walk")

    } else {

        /* 
         To help with checking for indexed array value misalignments, create a deep copy of the inOptionsMap
        */
    
        const originalOptions = _.cloneDeep(optionsMap)
        let failedOptionCheck = false

        do {

            if (!_.isUndefined(loadingResultCallback) && !_.isUndefined(result)) loadingResultCallback(result)

            if (!_.isUndefined(loadingCallback)) loadingCallback(key || _.head(remaining)[0])

            result = await step(delegate, carrier, plan, census, input, remaining, enteredInPast, fieldInfo,
                optionsMap, key, value, isMulti)

            if (result.continue) {

                input = result.input
                remaining = result.remaining
                enteredInPast = result.pastInput
                optionsMap = result.optionsMap || {}

                if (!result.isError || key === result.next) {

                    /* Got rates or current key/value was not accepted */

                    keepWalking = false

                } else {

                    /* Didn't get rates but the key/value passed ... now we can see if we can keep walking on our own */

                    if (!_.isUndefined(optionsMap[result.next]) && optionsMap[result.next].length === 1) {

                        /* No need to make the user enter this value to keep going */

                        key = result.next
                        value = optionsMap[result.next].type === Constants.OptionTypes.Array ? optionsMap[result.next].options[0] :
                            optionsMap[result.next].options[0].index

                    } else {
                    
                        /* 1 of 3 conditions could keep us walking */

                        /* 1. The value in the remaining - this represents what the user has entered in this session */

                        let nextValue = (_.head(result.remaining) || ['nothing left', undefined])[1]

                        /* 2. A default value has been given to us */
                        
                        /* 3. Special handling for specific key (NOT CARRIER!) */
                        
                        const defaultValue = defaults[result.next]
                        const specialHandlingValue = specialKeyHandler(result.next, censusTotal)
                        const defaultOrSpecial = _.isUndefined(defaultValue) ? specialHandlingValue : defaultValue
                        
                        nextValue = _.isUndefined(nextValue) ? defaultOrSpecial : nextValue

                        if (_.isUndefined(nextValue)) {

                            keepWalking = false

                        } else {

                            /* 
                             There could be an issue where the same field name on different carriers results in different ordered
                             indexed arrays. In this case, we have to do a value check to make sure that the actual value the user
                             selected is the same as it is now. If not, there are some fields that we might be able to figure out the
                             mapping (if safe) but otherwise the user will have to reenter the value.
    
                             1. Make sure we're dealing with indexed array. otherwise, it doesn't matter for this check
                             2. Pull the value from the new and old arrays and see if they match.
                             3. If no match, send to function that will determine the mapping based on key
                            */
                        
                            if (!_.isUndefined(optionsMap[result.next]) && !_.isUndefined(originalOptions[result.next])) {

                                if (originalOptions[result.next].type === Constants.OptionTypes.IndexedArray
                                    && optionsMap[result.next].type === Constants.OptionTypes.IndexedArray) {
                                
                                    const originalSelection = _.find(originalOptions[result.next].options, obj => obj.index === nextValue)
                        
                                    if (!_.isUndefined(originalSelection)) {

                                        const orginalValue = _.toLower(originalSelection.desc)
                                        const mappedToNew = _.find(optionsMap[result.next].options, obj => {
                                        
                                            return _.toLower(obj.desc) === orginalValue
                                        })
    
                                        if (!_.isUndefined(mappedToNew)) {
    
                                            nextValue = mappedToNew.index
    
                                        } else {
    
                                            console.error(`Failed to find options mapping for [${result.next}] - [${orginalValue}] in new options`)
                                            console.error(optionsMap[result.next].options)
    
                                            nextValue = undefined
                                            failedOptionCheck = true
                                        }
        
                                    } else {
                                
                                        console.error(`Expected to find option selection for [${result.next}] - [${nextValue}]`)
                                        console.error(originalOptions[result.next].options)
    
                                        nextValue = undefined
                                        failedOptionCheck = true
                                    }
                                
                                } else if (originalOptions[result.next].type !== optionsMap[result.next].type) {

                                    console.error(`Found case where option type changed [${carrier}] - [${result.next}] - [${nextValue}]`)
                                    console.error(originalOptions[result.next])
                                    console.error(optionsMap[result.next])
    
                                    nextValue = undefined
                                    failedOptionCheck = true
                                }
                            }

                            if (!failedOptionCheck) {

                                /* 
                                Since we have a value and the refreshed options, we can just check the value against the options 
                                and to make sure the value is still good
                                */

                                if (!_.isUndefined(optionsMap[result.next])) {

                                    const options = optionsMap[result.next].type === Constants.OptionTypes.Array ? optionsMap[result.next].options :
                                        _.map(optionsMap[result.next].options, obj => obj.index)

                                    if (_.isUndefined(_.find(options, v => v === nextValue))) {

                                        keepWalking = false
                                    }
                                }

                                key = result.next
                                value = nextValue

                            } else {

                                keepWalking = false
                            }
                        }
                    }
                }

            } else {

                keepWalking = false
            }

        } while (keepWalking)

        if (result.continue) {

            /* 
             As we leave, we need to make sure that options from past that have not yet been reached (still in remaining) are not lost or
             else we won't be able to compare on next step.
            */
    
            if (!_.isEmpty(_.compact(_.values(originalOptions))) && result.remaining.length > 1) {

                const optionsToAdd = _.compact(_.map(_.tail(result.remaining), pair => {

                    if (_.isUndefined(pair[1])) {

                        return undefined

                    } else {

                        if (_.isUndefined(originalOptions[pair[0]]) || originalOptions[pair[0]].type !== Constants.OptionTypes.IndexedArray) {

                            return undefined

                        } else {

                            return [pair[0], originalOptions[pair[0]]]
                        }
                    }
                }))

                if (optionsToAdd.length > 0) {

                    const newOptionsMap = _.fromPairs(_.concat(_.toPairs(optionsMap), optionsToAdd))

                    console.log(`Updating outgoing result optionsMap`)
                    console.log(newOptionsMap)
                    console.log({ ...result, optionsMap: newOptionsMap })

                    result = { ...result, optionsMap: newOptionsMap }
                }
            }
        }

        return result || onErrorResponse("Something really bad happened - so tell user that we'll get back to them later")
    }
}
