import axios from 'axios'

export const InternalCommErrorCodes = {

    callFailedWithStatus: -9901,
    callFailedNoStatus: -9902,
    callTimedOut: -9003
}

export const makeCall = (url, method, data, headersIn, timeout = 5000) => {

    return axios({
        url: url,
        method: method,
        data: data,
        timeout: timeout,
        headers: { ...headersIn },
        responseType: 'json'
    })
    .then(response => response.data)
    .catch(error => {

        if (error.response) {

            return {
                isError: true,
                errorCode: InternalCommErrorCodes.callFailedWithStatus,
                response: error.response
            }

        } else {

            console.log(error)

            return {
                isError: true,
                errorCode: error.code === 'ECONNABORTED' ? InternalCommErrorCodes.callTimedOut : InternalCommErrorCodes.callFailedNoStatus,
                response: error
            }
        }
    })
}