const Constants = {

    MajorMedRateLoadFactor: 0.00,
    APIKeys: {
        String: 'String',
        Long: 'Long',
        Double: 'Double',
        Boolean: 'Boolean',
        Date: "Date",
        Percent: "Percent",
        RequiredKey: "required",
        ValidationKey: "validation",
        InvalidTypeKey: "invalid-type",
        InvalidSelectionKey: "invalid-selection",
        InvalidFormatKey: "invalid-format",
        WarningsKey: "warnings",
        IdKey: "id",
        RequestIdKey: "requestId"
    },
    SingleStepStatus: {
        TargetAcceptedNextFailed: 0,
        TargetAcceptedNextChanged: 1,
        TargetFailed: 2,
        Accepted: 3,
        PreviousValidationError: 4,
        GotRates: 5
    },
    SingleStepValidationTypes: {
        Validation: 'validation',
        Format: 'format',
        Options: 'options',
        Type: 'type'
    },
    OptionTypes: {
        IndexedArray: 'indexedArray',
        Array: 'array'
    },
    Non400DelegateTriggerValue: "invalid",
    Test4TierCensus: {
        "underForty": {
            "insured": 5,
            "insuredPlusFamily": 5,
            "insuredPlusChildren": 5,
            "insuredPlusSpouse": 5
        },
        "fortyToFortyNine": {
            "insuredPlusSpouse": 5,
            "insuredPlusChildren": 5,
            "insuredPlusFamily": 5,
            "insured": 5
        },
        "fiftyPlus": {
            "insuredPlusSpouse": 5,
            "insuredPlusChildren": 5,
            "insuredPlusFamily": 5,
            "insured": 5
        }
    },
    Test3TierCensus: {
        "underForty": {
            "insured": 5,
            "insuredPlusFamily": 5,
            "insuredPlusOne": 0
        },
        "fortyToFortyNine": {
            "insuredPlusOne": 0,
            "insuredPlusFamily": 0,
            "insured": 0
        },
        "fiftyPlus": {
            "insuredPlusOne": 0,
            "insuredPlusFamily": 0,
            "insured": 0
        }
    },
    Test2TierCensus: {
        "underForty": {
            "insured": 4,
            "insuredPlusFamily": 0
        },
        "fortyToFortyNine": {
            "insured": 0,
            "insuredPlusFamily": 0
        },
        "fiftyPlus": {
            "insured": 0,
            "insuredPlusFamily": 0
        }
    },
}

export default Constants